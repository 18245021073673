import '../scss/main.scss';

const SecureToken = '5cd369dd-a0f4-4998-a6fa-f0a0a0f05514';
const emailTo = 'EliteVehicleServicing@outlook.com';
const emailFrom = 'website@evsblandford.com';

const nameMinLength = 2;
const messageMinLength = 4;
const emailMinLength = 6;
const phoneMinLength = 9;
const makeMinLength = 2;

// eslint-disable-next-line func-names
window.onload = function () {
  function sendMessage(Subject, Body) {
    // eslint-disable-next-line no-undef
    Email.send({
      SecureToken,
      To: emailTo,
      From: emailFrom,
      Subject,
      Body,
    }).then(
      (message) => {
        if (message === 'OK') {
          document.getElementById('form-message').innerText = 'Thank you, your message was successfully sent. We will get back to you soon.';
          document.getElementById('form-message-error').innerText = '';
        } else {
          console.log(message);
          document.getElementById('form-message-error').innerText = 'Sorry, there has been a problem sending the message. Please phone or email us directly.';
        }
        document.getElementById('form-btn').remove();
      },
    );
  }

  // eslint-disable-next-line func-names
  window.submitContactForm = function () {
    const name = document.getElementById('inputName');
    const email = document.getElementById('inputEmail');
    const phone = document.getElementById('inputPhone');
    const message = document.getElementById('inputMessage');

    if (name.value.length >= nameMinLength
      && email.value.length >= emailMinLength
      && phone.value.length >= phoneMinLength
      && message.value.length >= messageMinLength) {
      const body = `<p>NAME: ${name.value}</p> 
      <p>PHONE: ${phone.value}</p> 
      <p>EMAIL: ${email.value}</p> 
      <p>MESSAGE: ${message.value}</p>`;
      sendMessage(`Website Message: ${name.value} : ${email.value}`, body);

      name.classList.remove('error');
      email.classList.remove('error');
      phone.classList.remove('error');
      message.classList.remove('error');
    } else {
      document.getElementById('form-message-error').innerText = 'Please complete the highlighted fields then send.';

      if (name.value.length < nameMinLength) {
        name.classList.add('error');
      } else {
        name.classList.remove('error');
      }

      if (email.value.length < emailMinLength) {
        email.classList.add('error');
      } else {
        email.classList.remove('error');
      }

      if (phone.value.length < phoneMinLength) {
        phone.classList.add('error');
      } else {
        phone.classList.remove('error');
      }

      if (message.value.length < messageMinLength) {
        message.classList.add('error');
      } else {
        message.classList.remove('error');
      }
    }
  };

  // eslint-disable-next-line func-names
  window.submitBookingForm = function () {
    const name = document.getElementById('inputName');
    const email = document.getElementById('inputEmail');
    const phone = document.getElementById('inputPhone');
    const registration = document.getElementById('inputRegistration');
    const make = document.getElementById('inputMake');
    const model = document.getElementById('inputModel');
    const checkboxService = document.getElementById('checkbox-service');
    const checkboxDiagnostics = document.getElementById('checkbox-diagnostics');
    const checkboxMechanicalRepair = document.getElementById('checkbox-mechanical-repair');
    const checkboxTyreReplacement = document.getElementById('checkbox-tyre-replacement');
    // eslint-disable-next-line max-len
    const checkboxAirConditioningRecharge = document.getElementById('checkbox-air-conditioning-recharge');
    const checkboxFordWetBeltReplacement = document.getElementById('checkbox-ford-wet-belt-replacement');
    const message = document.getElementById('inputMessage');

    let services = '';
    if (checkboxService.checked) {
      services += `${checkboxService.value}, `;
    }
    if (checkboxDiagnostics.checked) {
      services += `${checkboxDiagnostics.value}, `;
    }
    if (checkboxMechanicalRepair.checked) {
      services += `${checkboxMechanicalRepair.value}, `;
    }
    if (checkboxTyreReplacement.checked) {
      services += `${checkboxTyreReplacement.value}, `;
    }
    if (checkboxAirConditioningRecharge.checked) {
      services += `${checkboxAirConditioningRecharge.value}`;
    }

    if (checkboxFordWetBeltReplacement.checked) {
      services += `${checkboxFordWetBeltReplacement.value}`;
    }

    if (name.value.length >= nameMinLength
      && email.value.length >= emailMinLength
      && phone.value.length >= phoneMinLength
      && make.value.length >= makeMinLength
      && message.value.length >= messageMinLength) {
      const body = `<p>NAME: ${name.value}</p> 
      <p>PHONE: ${phone.value}</p> 
      <p>EMAIL: ${email.value}</p> 
      <p>REGISTRATION: ${registration.value}</p> 
      <p>MAKE: ${make.value}</p>
      <p>MODEL: ${model.value}</p>
      <p>SERVICES: ${services}</p>  
      <p>MESSAGE: ${message.value}</p>`;
      sendMessage(`Website Booking: ${name.value} : ${email.value}`, body);
      name.classList.remove('error');
      email.classList.remove('error');
      phone.classList.remove('error');
      make.classList.remove('error');
      message.classList.remove('error');
    } else {
      document.getElementById('form-message-error').innerText = 'Please complete the highlighted fields then send.';

      if (name.value.length < nameMinLength) {
        name.classList.add('error');
      } else {
        name.classList.remove('error');
      }

      if (email.value.length < emailMinLength) {
        email.classList.add('error');
      } else {
        email.classList.remove('error');
      }

      if (phone.value.length < phoneMinLength) {
        phone.classList.add('error');
      } else {
        phone.classList.remove('error');
      }

      if (make.value.length < makeMinLength) {
        make.classList.add('error');
      } else {
        make.classList.remove('error');
      }

      if (message.value.length < messageMinLength) {
        message.classList.add('error');
      } else {
        message.classList.remove('error');
      }
    }
  };
};
